/********************************************************************************
*                                                                               *
* This document and any information contained herein is the property of         *
* Eddyfi NDT Inc. and is protected under copyright law. It must be considered   *
* proprietary and confidential and must not be disclosed, used, or reproduced,  *
* in whole or in part, without the written authorization of Eddyfi NDT Inc.     *
*                                                                               *
* Le pr�sent document et l�information qu�il contient sont la propri�t�         *
* exclusive d�Eddyfi NDT Inc. et sont prot�g�s par la loi sur le droit          *
* d�auteur. Ce document est confidentiel et ne peut �tre divulgu�, utilis� ou   *
* reproduit, en tout ou en partie, sans l'autorisation �crite d�Eddyfi NDT Inc. *
*                                                                               *
********************************************************************************/

import React, { Component } from 'react';
import { Route } from 'react-router';
import { withTranslation } from 'react-i18next'
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Activate } from './components/Activate';
import { Release } from './components/Release';

import './custom.css'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'en'
    };
  }

  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home}/>
        <Route path='/activate' component={Activate}/>
        <Route path='/release' component={Release}/>
      </Layout>
    );
  }
}

export default withTranslation()(App);
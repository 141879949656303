/********************************************************************************
*                                                                               *
* This document and any information contained herein is the property of         *
* Eddyfi NDT Inc. and is protected under copyright law. It must be considered   *
* proprietary and confidential and must not be disclosed, used, or reproduced,  *
* in whole or in part, without the written authorization of Eddyfi NDT Inc.     *
*                                                                               *
* Le pr�sent document et l�information qu�il contient sont la propri�t�         *
* exclusive d�Eddyfi NDT Inc. et sont prot�g�s par la loi sur le droit          *
* d�auteur. Ce document est confidentiel et ne peut �tre divulgu�, utilis� ou   *
* reproduit, en tout ou en partie, sans l'autorisation �crite d�Eddyfi NDT Inc. *
*                                                                               *
********************************************************************************/

import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Home() {

  const history = useHistory();

  return (
    <div>
      <p>For simple and quick activation or release of your license, please connect the computer or instrument which will run the software to the internet. It can also be done using an alternative web connection (such as a cellular phone hot spot) if a direct connection is not available.</p>
      <p>However, if you are unable to connect to the internet at this time, please use this offline license management tool to activate your software license or release a license (computers only) so that either a colleague or you can use the software on an alternative device.</p>
      <br/>
      <p>Please choose whether you would like to <strong>Activate</strong> or <strong>Release</strong> your license</p>
      <Container fluid>
        <Row>
          <Col lg={4} md={6} className="col-custom-margin">
            <Button variant="primary" size="xxl" block onClick={() => history.push('/activate')}>
              <Trans>home.activateLicense</Trans>
            </Button>
          </Col>
          <Col lg={4} md={6} className="col-custom-margin">
            <Button variant="primary" size="xxl" block onClick={() => history.push('/release')}>
              <Trans>home.releaseLicense</Trans>
            </Button>
          </Col>
          <Col lg={4}>
          </Col>
        </Row>
      </Container>
      <br /><br />
    </div>
  );
}

export {Home};
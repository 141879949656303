/********************************************************************************
*                                                                               *
* This document and any information contained herein is the property of         *
* Eddyfi NDT Inc. and is protected under copyright law. It must be considered   *
* proprietary and confidential and must not be disclosed, used, or reproduced,  *
* in whole or in part, without the written authorization of Eddyfi NDT Inc.     *
*                                                                               *
* Le pr�sent document et l�information qu�il contient sont la propri�t�         *
* exclusive d�Eddyfi NDT Inc. et sont prot�g�s par la loi sur le droit          *
* d�auteur. Ce document est confidentiel et ne peut �tre divulgu�, utilis� ou   *
* reproduit, en tout ou en partie, sans l'autorisation �crite d�Eddyfi NDT Inc. *
*                                                                               *
********************************************************************************/

import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <NavMenu/>
        <Container>
          {this.props.children}
        </Container>
        <Footer />
      </div>
    );
  }
}
/********************************************************************************
*                                                                               *
* This document and any information contained herein is the property of         *
* Eddyfi NDT Inc. and is protected under copyright law. It must be considered   *
* proprietary and confidential and must not be disclosed, used, or reproduced,  *
* in whole or in part, without the written authorization of Eddyfi NDT Inc.     *
*                                                                               *
* Le présent document et l’information qu’il contient sont la propriété         *
* exclusive d’Eddyfi NDT Inc. et sont protégés par la loi sur le droit          *
* d’auteur. Ce document est confidentiel et ne peut être divulgué, utilisé ou   *
* reproduit, en tout ou en partie, sans l'autorisation écrite d’Eddyfi NDT Inc. *
*                                                                               *
********************************************************************************/

import React, { Component } from 'react';

import './Footer.css';

export class Footer extends Component {

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
      <div className="footer">
        <div className="text-center p-3">
          © {this.getYear()} - Eddyfi Technologies - All right reserved
        </div>
      </div>
    );
  }
}
/********************************************************************************
*                                                                               *
* This document and any information contained herein is the property of         *
* Eddyfi NDT Inc. and is protected under copyright law. It must be considered   *
* proprietary and confidential and must not be disclosed, used, or reproduced,  *
* in whole or in part, without the written authorization of Eddyfi NDT Inc.     *
*                                                                               *
* Le présent document et l’information qu’il contient sont la propriété         *
* exclusive d’Eddyfi NDT Inc. et sont protégés par la loi sur le droit          *
* d’auteur. Ce document est confidentiel et ne peut être divulgué, utilisé ou   *
* reproduit, en tout ou en partie, sans l'autorisation écrite d’Eddyfi NDT Inc. *
*                                                                               *
********************************************************************************/

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import languageEN from './locales/en/translation.json'
import languageFR from './locales/fr/translation.json'

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      fr: languageFR
    },
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    }
  });

export default i18n;
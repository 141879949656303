/********************************************************************************
*                                                                               *
* This document and any information contained herein is the property of         *
* Eddyfi NDT Inc. and is protected under copyright law. It must be considered   *
* proprietary and confidential and must not be disclosed, used, or reproduced,  *
* in whole or in part, without the written authorization of Eddyfi NDT Inc.     *
*                                                                               *
* Le pr�sent document et l�information qu�il contient sont la propri�t�         *
* exclusive d�Eddyfi NDT Inc. et sont prot�g�s par la loi sur le droit          *
* d�auteur. Ce document est confidentiel et ne peut �tre divulgu�, utilis� ou   *
* reproduit, en tout ou en partie, sans l'autorisation �crite d�Eddyfi NDT Inc. *
*                                                                               *
********************************************************************************/

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

registerPlugin(FilePondPluginFileValidateSize);


export function Release() {
  const history = useHistory();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    history.push('/');

  }
  const handleShow = () => setShow(true);

  return (
    <div>
      <h1>
        <Trans>releaseLicense.title</Trans>
      </h1>
      <p>Here, you can release your software license for your offline computer or instrument. Please follow the steps below:</p>
      <h2>Step 1</h2>
      <hr/>
      <p>To save the offline release request file, go back to the offline computer or instrument which you will be working on and launch the software. Access the software license management window, then click on Manage, followed by Offline, and then release license. Save the offline release request file onto a USB flash drive.</p>
      <h2>Step 2</h2>
      <hr/>
      <p>
        Insert the USB flash drive into a USB port on this device and select the release request file (<strong>ReleaseRequest.eddylic</strong>) below, then click on the&nbsp;
        <button className="filepond--file-action-button" type="button" data-align="right" style={{
          backgroundColor: 'black',
          cursor: 'default'
        }}>
          <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 10.414v3.585a1 1 0 0 1-2 0v-3.585l-1.293 1.293a1 1 0 0 1-1.414-1.415l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1-1.414 1.415L14 10.414zM9 18a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2H9z" fill="currentColor" fill-rule="evenodd"></path>
          </svg>
        </button>&nbsp;button.
      </p>
      <FilePond
        allowMultiple={false}
        instantUpload={false}
        labelIdle={t('releaseLicense.fileIdle')}
        labelFileWaitingForSize={t('releaseLicense.fileWaitingForSize')}
        labelFileSizeNotAvailable={t('releaseLicense.fileSizeNotAvailable')}
        labelFileLoading={t('releaseLicense.fileLoading')}
        labelFileLoadError={t('releaseLicense.fileLoadError')}
        labelFileProcessing={t('releaseLicense.fileProcessing')}
        labelFileProcessingError={(response) => { return t(response.body) }}
        labelFileProcessingComplete={t('releaseLicense.fileProcessingComplete')}
        labelFileProcessingAborted={t('releaseLicense.fileProcessingAborted')}
        labelFileRemoveError={t('releaseLicense.fileRemoveError')}
        labelTapToCancel={t('releaseLicense.tapToCancel')}
        labelTapToRetry={t('releaseLicense.tapToRetry')}
        labelButtonRemoveItem={t('releaseLicense.buttonRemoveItem')}
        labelButtonAbortItemLoad={t('releaseLicense.buttonAbortItemLoad')}
        labelButtonRetryItemLoad={t('releaseLicense.buttonRetryItemLoad')}
        labelButtonAbortItemProcessing={t('releaseLicense.buttonAbortItemProcessing')}
        labelButtonUndoItemProcessing={t('releaseLicense.buttonUndoItemProcessing')}
        labelButtonRetryItemProcessing={t('releaseLicense.buttonRetryItemProcessing')}
        labelButtonProcessItem={t('releaseLicense.buttonProcessItem')}
        labelMaxFileSizeExceeded={t('releaseLicense.maxFileSizeExceeded')}
        labelMaxFileSize={t('releaseLicense.maxFileSize')}
        server={{
          process: {
            url: '/release',
            method: 'POST',
            onload: (response) => {
              handleShow();

              return response.key;
            },
            onerror: (response) => {
              return response;
            }
          },
          post: null,
          revert: null,
          restore: null,
          load: null,
          fetch: null
        }}
        name="file"
        allowFileSizeValidation={true}
        minFileSize="100B"
        maxFileSize="1MB"/>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>releaseLicense.operationCompleted</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>The license was successfully released!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            <Trans>releaseLicense.ok</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
      <br/><br/>
    </div>
  );
}